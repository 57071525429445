import { ApolloClient, InMemoryCache } from '@apollo/client';

const client = new ApolloClient({
  connectToDevTools: true,
  // ssrMode: typeof window === 'undefined',
  uri: process.env.NEXT_PUBLIC_SERVER_URL,
  cache: new InMemoryCache({
    typePolicies: {
      Country: {
        keyFields: ['code'],
      },
      Query: {
        fields: {
          country: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: 'Country',
                code: args?.code,
              });
            },
          },
        },
      },
    },
  }),
});

export default client;
